/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';


const mock = [
  {
    
    description:
      'World Environment Day-2023 Class 6-12',
    href : '/ev-day-class6-12-202'
 
  },

  {
    
    description:
      'World Environment Day-2023 Class 5',
    href : '/ev-day-class5-2023'
 
  },  
  
  {
    
    description:
      'World Environment Day-2023 Class 4',
    href : '/ev-day-class4-2023'
 
  }, 
  {
    
    description:
      'World Environment Day-2023 Class 3',
    href : '/ev-day-class3-2023'
 
  }, 
  {
    
    description:
      'World Environment Day-2023 Class Pre-Primary',
    href : '/ev-day-preprimary-2023'
 
  },
  {
    
    description:
      'Eco Association 2023 Class 6',
    href : '/poster2023'
 
  }, 
  {
    
    description:
      'Eco Association 2023 Class 7',
    href : '/awareness2023'
 
  }, 
  {
    
    description:
      'Eco Association 2023 Class 8',
    href : '/greeting-card-2023'
 
  }, 
  {
    
    description:
      'Eco Association 2023 Class 9',
    href : '/eco-cls9-2023'
 
  }, 
  
  {
    
    description:
      'Eco Association 2023 Class 10',
    href : '/eco-cls10-2023'
 
  }, 

  {
    
    description:
      'Eco Association 2023 Class 11',
    href : '/eco-cls11-2023'
 
  }, 
  {
    
    description:
      'Eco Association 2023 Class 12',
    href : '/eco-cls12-2023'
 
  }, 
  {
    
    description:
      'Van Mahotsav 2023 Class 1-2',
    href : '/van-mahotsav-2023'
 
  }, 
  {
    
    description:
      ' Swachh Bharat Abhiyaan Class 4-5',
    href : '/swachh-bharat-2023'
 
  },

];

const Ecoclub2023 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Eco Club 2023-24
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
     
    </Box>

  );
};

export default Ecoclub2023;

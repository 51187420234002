import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/1.jpg';
// import p2 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/2.jpg';
// import p3 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/3.jpg';
// import p4 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/4.jpg';
// import p5 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/5.jpg';
// import p6 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/6.jpg';
// import p7 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/7.jpg';
// import p8 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/8.jpg';
// import p9 from 'assests/Photos/Clubs/CulturalClub/2024/TalenShow/9.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import CulturalHeritageClub from '../CulturalHeritageClub';


const PotteryActivity2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
const p1 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/4.webp`;
const p5 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/5.webp`;
const p6 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/6.webp`;
const p7 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/7.webp`;
const p8 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/8.webp`;
const p9 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/9.webp`;
const p10 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/10.webp`;
const p11 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/11.webp`;
const p12 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/12.webp`;
const p13 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/13.webp`;
const p14 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/14.webp`;
const p15 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/15.webp`;
const p16 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/16.webp`;
const p17 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/17.webp`;
const p18 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/18.webp`;
const p19 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/19.webp`;
const p20 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/20.webp`;
const p21 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/21.webp`;
const p22 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/22.webp`;
const p23 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/23.webp`;
const p24 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/24.webp`;
const p25 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/25.webp`;
const p26 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/26.webp`;
const p27 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/27.webp`;
const p28 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/28.webp`;
const p29 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/29.webp`;
const p30 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/30.webp`;
const p31 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/31.webp`;
const p32 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/32.webp`;
const p33 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/33.webp`;
const p34 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/34.webp`;
const p35 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/35.webp`;
const p36 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/36.webp`;
const p37 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/37.webp`;
const p38 = `${config.base_image_url}/home/events-activities/clubs/cultural-heritage-club/POTTERY/38.webp`;


 
  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 1,
      },
      {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
      {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
      },
      {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
      },
      {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
      },
      {
        src: p11,
        source: p11,
        rows: 1,
        cols: 1,
      },
      {
        src: p12,
        source: p12,
        rows: 1,
        cols: 1,
      },
      {
        src: p13,
        source: p13,
        rows: 1,
        cols: 1,
      },
      {
        src: p14,
        source: p14,
        rows: 1,
        cols: 1,
      },
      {
        src: p15,
        source: p15,
        rows: 1,
        cols: 1,
      },
      {
        src: p16,
        source: p16,
        rows: 1,
        cols: 1,
      },
      {
        src: p17,
        source: p17,
        rows: 1,
        cols: 1,
      },
      {
        src: p18,
        source: p18,
        rows: 1,
        cols: 1,
      },
      {
        src: p19,
        source: p19,
        rows: 1,
        cols: 1,
      },
      {
        src: p20,
        source: p20,
        rows: 1,
        cols: 1,
      },
      {
        src: p21,
        source: p21,
        rows: 1,
        cols: 1,
      },
      {
        src: p22,
        source: p22,
        rows: 1,
        cols: 1,
      },
      {
        src: p23,
        source: p23,
        rows: 1,
        cols: 1,
      },
      {
        src: p24,
        source: p24,
        rows: 1,
        cols: 1,
      },
      {
        src: p25,
        source: p25,
        rows: 1,
        cols: 1,
      },
      {
        src: p26,
        source: p26,
        rows: 1,
        cols: 1,
      },
      {
        src: p27,
        source: p27,
        rows: 1,
        cols: 1,
      },
      {
        src: p28,
        source: p28,
        rows: 1,
        cols: 1,
      },
      {
        src: p29,
        source: p29,
        rows: 1,
        cols: 1,
      },
      {
        src: p30,
        source: p30,
        rows: 1,
        cols: 1,
      },
      {
        src: p31,
        source: p31,
        rows: 1,
        cols: 1,
      },
      {
        src: p32,
        source: p32,
        rows: 1,
        cols: 1,
      },
      {
        src: p33,
        source: p33,
        rows: 1,
        cols: 1,
      },
      {
        src: p34,
        source: p34,
        rows: 1,
        cols: 1,
      },
      {
        src: p35,
        source: p35,
        rows: 1,
        cols: 1,
      },
      {
        src: p36,
        source: p36,
        rows: 1,
        cols: 1,
      },
      {
        src: p37,
        source: p37,
        rows: 1,
        cols: 1,
      },
      {
        src: p38,
        source: p38,
        rows: 1,
        cols: 1,
      },
      
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Creative and Cultural Exploration
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classe: PRE-PRIMARY   &  Date: 03 September 2024
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"From little seeds grow mighty trees. Let's show our talents with joy and ease!"

<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Pre-Primary participated in an enriching and joyful experience centered around the making of Lord Ganesha models using natural clay as part of the monthly celebrations on Tuesday, 03 September 2024.
        <br></br>
        Our students had the opportunity to immerse themselves in a creative and culturally enriching experience through a special pottery and Ganesha making session. This immersive experience introduced children to the art of working with natural clay, allowing them to create beautiful and meaningful pieces.
        <br></br>
        Guided by skilled artisans, the children learned the basics of pottery, from shaping and molding clay to adding intricate details. They were excited to see how simple clay could be transformed into unique and functional art. The session's highlight was the crafting of Ganesha idols, where the children not only practiced their new pottery skills but also connected with cultural traditions.
        <br></br>
        This activity was designed to nurture creativity and fine motor skills while also emphasising the importance of using eco-friendly, natural materials. The children took great pride in their creations, gaining a deeper appreciation for both art and culture.
        <br></br>
        Overall, the pottery and Ganesha making session was a joyful and educational experience that left our young learners with lasting memories and a sense of accomplishment. At National Public School Yeshwanthpur, we continue to provide our students with enriching opportunities that inspire a love for learning and creativity and to shine and show how their talents can light up the world!
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
Watch out, world! Future artists at work!
<br/>
Clay, creativity, and cuteness overload!

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <CulturalHeritageClub/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default PotteryActivity2024;